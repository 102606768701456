import React from "react";

function BannerBathemetry() {
    return(
        <div className="sidebar">
        <div className="content">
          <div className="BannerHeading">
            <h4>
              <center>Bathemetry Detection</center>
            </h4>
        </div>
        </div>
        </div>
    )
}

export default BannerBathemetry;