import React, { useState, useContext } from "react";
import NavBar from "./Components2/NavBar.js";
import "./App.css";
import Banner from "./Components2/BANNER/Banner.js";
import OpenLayersMap from "./Components2/BANNER/Main.js";
import Draw from "ol/interaction/Draw.js";
import drawcontext from "./Components2/context.js";
import Sidebar from "./Components2/Sidebar.js";
import Banneroil from "./Components2/BANNER/BannerOil.js";
import BannerShoreLine from "./Components2/BANNER/BannerShoreLine.js";
import Mangrove from "./Components2/BANNER/BannerMangrove.js";
import Bathemetry from "./Components2/BANNER/BannerBathemetry.js";
import "./Components2/Navbar.css";
function App() {
	const Clickedstyle = {
		position: "fixed",
		height: "100%",
		width: "80%",
		left: "306px",
		// transition: 'all 0.3s ease-in'
	};
	const [mapObject, setMapObject] = useState(null);
	const [source, setVectorSource] = useState(null);
	const [loading, setLoading] = useState(false);

	const [activeTab, setActiveTab] = useState("");
	const [sideBarOn, isSideBarOn] = useState(false);
	function setaddInteraction(draw) {
		if (draw) {
			mapObject.removeInteraction(draw);
		}
		draw = new Draw({
			source: source,
			type: "Point",
		});
		draw.on("drawend", (evt) => { });
		mapObject.addInteraction(draw);
		return draw;
	}

	// Function to handle the ship detection link click
	const handleShipDetectionClick = () => {
		setActiveTab("ship-detection");
	};

	const handleOilSpillDetectionClick = () => {
		setActiveTab("oil-spill-detection");
	};

	const handleShoreLineDetectionClick = () => {
		setActiveTab("shore-line-detection");
	};

	const handleMangroveDetectionClick = () => {
		setActiveTab("mangroove-detection");
	};
	const handleBathemetryDetectionClick = () => {
		setActiveTab("bathemetry-detection");
	};

	const handleSidebarHover = () => {
		isSideBarOn(!sideBarOn)
		// console.log(sideBarOn);
	};

	const handleSidebarLeave = () => {

	};

	const handleBannerClose = () => {
		setActiveTab(""); // Resetting the activeTab state
	  };
	  
	return (
		<div>
			<drawcontext.Provider
				value={{ mapObject, setMapObject, source, setVectorSource }}
			>
				<div className="mainDiv">
					{loading && <div className="loading-overlay">Loading...</div>}
					<div className='navbar'>
						<img className="logo" src={require("./Components2/assets/geocoast_logo.png")} alt="" />
						<img className="logo2" src={require("./Components2/assets/GCRS_Logo.png")} alt="" />
					</div>

					<div className="subDiv">
						<Sidebar
							onShipDetectionClick={handleShipDetectionClick}
							onOilSpillDetectionClick={handleOilSpillDetectionClick}
							onShoreLineDetectionClick={handleShoreLineDetectionClick}
							onMangroveDetectionClick={handleMangroveDetectionClick}
							onBathemetryDetectionClick={handleBathemetryDetectionClick}
							onSidebarHover={handleSidebarHover}
							onSidebarLeave={handleSidebarLeave}
						/>
						<div className="sub-sub-Div">
							{activeTab === "ship-detection" ? (
								<>
									<Banner drawToolControl={setaddInteraction} sideBarOn={sideBarOn} onCloseBanner={handleBannerClose} />
									<OpenLayersMap />
								</>
							) : activeTab === "oil-spill-detection" ? (
								<>
									<Banneroil />
									<OpenLayersMap />
								</>
							) : activeTab === "shore-line-detection" ? (
								<>
									<BannerShoreLine />
									<OpenLayersMap />
								</>
							) : activeTab === "mangroove-detection" ? (
								<>
									<Mangrove />
									<OpenLayersMap />
								</>
							) : activeTab === "bathemetry-detection" ? (
								<>
									<Bathemetry />
									<OpenLayersMap />
								</>
							) : (
								<OpenLayersMap sideBarOn={sideBarOn} />
							)}
						</div>
					</div>
				</div>
			</drawcontext.Provider>
		</div>
	);
}
export default App;
