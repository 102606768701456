import React, { useEffect, useState, useContext } from "react";
import "./Banner.css";
import "react-datepicker/dist/react-datepicker.css";
import DateSelector from "./date";
import "./date.css";
import "./visualize.css";
import LocationDropdown from "./droplist";
import drawcontext from "../context";
import { fromLonLat } from "ol/proj.js";
import ShorelineVisualize from "./shorelinevisualize";
// import { getDateString } from './run';
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ.js";

function BannerShoreLine(props) {
	const [coordinates, setCoordinates] = useState(null);
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [smallNav, setSmallNav] = useState("Discover");
	const { mapObject } = useContext(drawcontext);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const [selectedLocation, setSelectedLocation] = useState(null);
	const [loading, setloading] = useState(false);

	const [showVisualize, setShowVisualize] = useState(false);
	const [resultLayer, setResultLayer] = useState(null);
	const [feature, setFeature] = useState(null);

	const [chartData, setChartData] = useState({});

	const drawBtnHandler = () => {
		const drawValue = props.drawToolControl();
		drawValue.on("drawend", (event) => {
			const geometry = event.feature.getGeometry();
			const [lon, lat] = geometry.getCoordinates();
			setCoordinates({ lon, lat });
		});
	};

	const handleLocationChange = (location) => {
		const { center, zoom } = location;
		const view = mapObject.getView();
		const newCenter = fromLonLat(center);
		view.animate({ center: newCenter, zoom: zoom });
	};

	const handleSmallNav = (navName) => {
		setSmallNav(navName);
	};

	const clearLayer = () => {
		if (resultLayer) {
			resultLayer.forEach((item) => {
				mapObject.removeLayer(item);
			});
		} else {
		}
	};

	// // static shoreline response ... run handle
	function static_shoreline_runhandle() {
		setloading(true);
		let shoreline_resp = {
			status_code: 1,
			data: [
				{
					year: 1990,
					tileUrl: "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/378fb559bbec3f0df5646acf307253d8-ecc0c2c3988de7ba5d3b84993d8d06d3/tiles/{z}/{x}/{y}",
					color: "#800080"
				},
				{
					year: 1995,
					tileUrl: "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/28db8b31dde956228f4dfb7bcbe2a092-b7e57709c7ed0de6f36f2cdd059ad2fd/tiles/{z}/{x}/{y}",
					color: "#0000FF"
				},
				{
					year: 2000,
					tileUrl:  "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/6b03dd573c669bc07cb0c0a6bd1b7b79-d2c26487fd6e6e528fe194c2709667df/tiles/{z}/{x}/{y}",
					color: "#00FFFF"
				},
				{
					year: 2005,
					tileUrl:  "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/5f475802a4e94f1362aa5a0dd36ea0cc-11565c8d1d311d07da0f7cc598617021/tiles/{z}/{x}/{y}",
					color: "#228B22"
				},
				{
					year: 2010,
					tileUrl: "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/d70c5228ff958af7735140ecb7180566-d68323922f2648dc4f3a047c40cc4fe0/tiles/{z}/{x}/{y}",
					color: "#FFD700"
				},
				{
					year: 2015,
					tileUrl: "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/733b07e2897230a90bd1b35e7c2d3675-715e5bc6b80c9d51704a4ae4e5e0d23f/tiles/{z}/{x}/{y}",
					color: "#FF8C00"
				},
				{
					year: 2020,
					tileUrl: "https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/0f6ad7ae649fb7a2e34c3d7a8ab4b321-d4676d7c3be1e01648f2b451167fc065/tiles/{z}/{x}/{y}",
					color: "#FF0000"
				},
			],
			error_message: null,
		};
		const layerData = [];
		shoreline_resp.data.forEach((item) => {
			// dateArray.push(item.date);
			// countArray.push(item.count);
			const resultTileLayer = new TileLayer({
				source: new XYZ({
					attributions:
						'Tiles © <a href="https://earthengine.google.com/">Google Earth Engine</a>',
					url: item.tileUrl,
				}),
				title: "shoreline - " + item.year,
				visible: true,
			});
			mapObject.addLayer(resultTileLayer);
			layerData.push({
				shorelineTile: resultTileLayer,
				title: "Shore Line - " + item.year,
				color: item.color,
			});
		});
		setloading(false);
		setShowVisualize(true);
		setSmallNav("Visualize");
		clearLayer();
	}

	// shoreline api handling
	function handleRunCLick(props) {
		setloading(true);

		let queryJson = {
			data: {
				aoi: {
					type: "FeatureCollection",
					features: [
						{
							type: "Feature",
							geometry: {
								type: "Polygon",
								coordinates: [
									[
										[106.80219696052866, 10.39796631362065],
										[106.756878555931, 10.410122671299996],
										[106.73078613912003, 10.284484218223586],
										[106.79121068193291, 10.251377568065578],
										[106.80219696052866, 10.39796631362065],
									],
								],
							},
							properties: { name: "user_aoi" },
						},
					],
				},
			},
		};
		// const dateArray = [];
		// const countArray = [];
		const layerData = [];
		let queryUrl = "https://geocoast.in/api/shoreline";
		// let queryUrl = 'http://127.0.0.1:3013/shoreline';
		fetch(queryUrl, { method: "POST", body: JSON.stringify(queryJson) })
			.then((resp) => {
				return resp.json();
			})
			.then((myJson) => {
				if (myJson.data.length) {
					myJson.data.forEach((item) => {
						// dateArray.push(item.date);
						// countArray.push(item.count);
						const resultTileLayer = new TileLayer({
							source: new XYZ({
								attributions:
									'Tiles © <a href="https://earthengine.google.com/">Google Earth Engine</a>',
								url: item.tileUrl,
							}),
							title: "shoreline - " + item.year,
							visible: true,
						});
						mapObject.addLayer(resultTileLayer);
						layerData.push({
							shorelineTile: resultTileLayer,
							title: "Shore Line - " + item.year,
							color: item.color,
						});
					});

					// console.log('resultlayer', resultLayer);
					console.log("layerData", layerData);
					// console.log(dateArray, countArray, tileUrl);
				} else {
					console.log("No tiles available");
				}

				setloading(false);
				setShowVisualize(true);
				setSmallNav("Visualize");
				clearLayer();
			})
			.catch((error) => {
				setloading(false);
				console.error(error);
			});
	}

	// function CallBackStartDate(startDate) {
	//   setStartDate(startDate);
	//   console.log('startDate', startDate);
	// }
	// function CallBackEndDate(endDate) {
	//   setEndDate(endDate);
	//   console.log('endDate', endDate);
	// }

	function callBackLocation(center) {
		setSelectedLocation(center);
	}

	return (
		<div className="sidebar">
			<div className="content">
				<div className="BannerHeading">
					<h4>
						<center>Shore-LINE Detection</center>
					</h4>
				</div>
				<div className="BannerTabs">
					<ul>
						<li
							className="disc_tab active"
							onClick={() => handleSmallNav("Discover")}
						>
							Discover
						</li>
						<li onClick={() => handleSmallNav("Visualize")}>Visualize </li>
					</ul>
				</div>

				{smallNav === "Discover" ? (
					<div className="discover">
						{/* <DateSelector
              handleCallBackStart={CallBackStartDate}
              startDate={startDate}
              endDate={endDate}
              handlCallBackEnd={CallBackEndDate}
            /> */}
						<LocationDropdown
							onLocationChange={handleLocationChange}
							onSelectedLocationChange={callBackLocation}
						/>

						<div className="lat-long-container">
							<div className="input-row">
								<label>Start Year:</label>
								<input
									type="number"
									step="0.01"
									value={latitude}
									onChange={(e) => setLatitude(e.target.value)}
								/>
							</div>
							<div className="input-row">
								<label>End Year:</label>
								<input
									type="number"
									step="0.01"
									value={longitude}
									onChange={(e) => setLongitude(e.target.value)}
								/>
							</div>
						</div>

						<button className="latlong-btn" onClick={drawBtnHandler}>
							Select Coordinates
						</button>
						{/* <button className="undo">Undo</button>
            <button className="reset">Reset</button> */}

						{coordinates && (
							<div>
								<p>Latitude: {coordinates.lat.toFixed(2)}</p>
								<p>Longitude: {coordinates.lon.toFixed(2)}</p>
							</div>
						)}

						{loading ? (
							<div>
								<img
									className="loding"
									src="https://cdn.dribbble.com/users/121337/screenshots/1024835/loading2.gif"
									alt=""
								/>
							</div>
						) : null}

						<div>
							<button className="run" onClick={static_shoreline_runhandle}>
								RUN
							</button>
						</div>
					</div>
				) : (
					<ShorelineVisualize />
				)}
			</div>
		</div>
	);
}

export default BannerShoreLine;
