import React, {useState} from 'react';
import "./Sidebar.css";
import { MdLocalShipping, MdOpacity, MdBeachAccess, MdNaturePeople } from 'react-icons/md';
import { FaShip,FaUmbrellaBeach,FaWater,FaTree } from 'react-icons/fa';
// import sideArrow from '../assets/chevron.svg'


function Sidebar({ onShipDetectionClick, onOilSpillDetectionClick,onShoreLineDetectionClick,onMangroveDetectionClick,onBathemetryDetectionClick,isResultsDivOpen,
  onSidebarHover, onSidebarLeave   }) {
  const [isSidebarHovered, setIsSidebarHovered] = useState(false);

  const handleSidebarHover = () => {
    setIsSidebarHovered(true);
    onSidebarHover(); 
  };

  const handleSidebarLeave = () => {
    setIsSidebarHovered(false);
    onSidebarLeave();
  };


  return (   
    <div className="side_icon" style={isSidebarHovered ? { width: '215px', overflow: 'visible' } : {}} onMouseEnter={handleSidebarHover} onMouseLeave={handleSidebarLeave}>
    {/* <div className='side_icon'> */}
       <ul className= "links" >
        <li>
          <a href="#" onClick={onShipDetectionClick}>
          <i className="fa-sharp fa-solid fa-ship"></i>
            {/* <span>{hoveredItem === 'Ship detection' ? 'Ship detection' : null}</span> */}
            <span className="nav-text">Ship Detection</span>
          </a>
        </li>
        <li>
          <a href="#"onClick={onOilSpillDetectionClick}>
            <i className="fa-solid fa-droplet"></i>
            <span className="nav-text" >Oil Spill Detection</span>
          </a> 
       </li>
        <li>
        <a href="#"onClick={onShoreLineDetectionClick}>
        <i className="fa-solid fa-bacon"></i>
           <span className="nav-text" >Shoreline Detection</span>
          </a> 
        </li>
        <li>
         <a href="#"onClick={onMangroveDetectionClick}>
         <i className="fa-solid fa-seedling"></i>
            <span className="nav-text" >Mangrove Detection</span>
          </a> 
        </li>
        <li>
        <a href="#" onClick={onBathemetryDetectionClick}>
        <i className="fa-solid fa-droplet"></i>
            <span className="nav-text">Bathemetry Detection</span>
          </a>
        </li>
        
       </ul>
       
       </div>
  )
}

export default Sidebar