import React, { useEffect, useState } from 'react';
import './shorelineVisualize.css'


function ShorelineVisualize() {

  return (
    <div>
      <div>
        <p>Legend</p>
        <div className='legend_div'> 
        <div className='main' ><div className='btn_cls c1990'></div><div>1990</div></div>
        <div className='main'><div className='btn_cls c1995'></div><div>1995</div></div>
        <div className='main'><div className='btn_cls c2000'></div><div>2000</div></div>
        <div className='main'><div className='btn_cls c2005'></div><div>2005</div></div>
        <div className='main'><div className='btn_cls c2010'></div><div>2010</div></div>
        <div className='main'><div className='btn_cls c2015'></div><div>2015</div></div>
        <div className='main'><div className='btn_cls c2020'></div><div>2020</div></div>
      </div>
      </div>
      
    </div>
  );
}

export default ShorelineVisualize;