import React, { useState, useContext } from "react";
import "./Banner.css";
import "react-datepicker/dist/react-datepicker.css";
import DateSelector from "./date";
import "./date.css";
import "./visualize.css";
import LocationDropdown from "./droplist";
import drawcontext from "../context";
import { fromLonLat } from "ol/proj.js";
import Visualize from "./visualize";
import { getDateString } from "./run";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ.js";

function Banner(props) {
	const [coordinates, setCoordinates] = useState(null);
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [smallNav, setSmallNav] = useState("Discover");
	const { mapObject } = useContext(drawcontext);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const [selectedLocation, setSelectedLocation] = useState(null);
	const [loading, setloading] = useState(false);

	const [showVisualize, setShowVisualize] = useState(false);
	const [resultLayer, setResultLayer] = useState(null);
	const [boundUrl, setBoundUrl] = useState([]);

	// const [showDetails, setShowDetails] = useState(false);
	const [chartData, setChartData] = useState({});

	const [showBanner, setShowBanner] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);



	const handleCloseBanner = () => {
		setShowConfirmation(true);
	};

	const handleConfirmClose = () => {
		setShowBanner(false);
		props.onCloseBanner(); // You can define onCloseBanner in App.js to reset the activeTab state
		setShowConfirmation(false);
	};

	const handleCancelClose = () => {
		setShowConfirmation(false);
	};

	const handleMinimize = () => {
		setShowBanner(!showBanner);
	};
	const drawBtnHandler = () => {
		const drawValue = props.drawToolControl();
		drawValue.on("drawend", (event) => {
			const geometry = event.feature.getGeometry();
			const [lon, lat] = geometry.getCoordinates();
			setCoordinates({ lon, lat });
		});
	};

	const handleLocationChange = (location) => {
		const { center, zoom } = location;
		const view = mapObject.getView();
		const newCenter = fromLonLat(center);
		view.animate({ center: newCenter, zoom: zoom });
	};

	const handleSmallNav = (navName) => {
		setSmallNav(navName);
	};

	const clearLayer = () => {
		if (resultLayer) {
			resultLayer.forEach((item) => {
				mapObject.removeLayer(item);
			});
		} else {
		}
	};



	// ship detection api handling
	function handleRunCLick(props) {
		setloading(true);
		setSmallNav("Visualize");

		const fstartDate = getDateString(props.startDate, "YYYY-mm-dd");
		const fendDate = getDateString(props.endDate, "YYYY-mm-dd");
		const locationCoordinate = selectedLocation;
		let queryJson = {
			data: {
				aoi: {
					type: "FeatureCollection",
					features: [
						{
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: locationCoordinate,
							},
							properties: { name: "user_aoi" },
						},
					],
				},
				dates: {
					from_date: fstartDate,
					to_date: fendDate,
				},
			},
		};

		const dateArray = [];
		const countArray = [];
		const tileUrl = [];
		const boundUrl = [];
		let queryUrl = "https://geocoast.in/api/shipcll/";
		// let queryUrl = 'http://127.0.0.1:3013/shipcll';
		fetch(queryUrl, { method: "POST", body: JSON.stringify(queryJson) })
			.then((resp) => {
				return resp.json();
			})
			.then((myJson) => {
				if (myJson.data.length) {
					myJson.data.forEach((item) => {
						dateArray.push(item.date);
						countArray.push(item.count);
					// Add ship Url layer
						const resultTileLayer = new TileLayer({
							source: new XYZ({
								attributions:
									'Tiles © <a href="https://earthengine.google.com/">Google Earth Engine</a>',
								url: item.tileUrl,
							}),
							title: "shipLayer",
							visible: false,
						});
						mapObject.addLayer(resultTileLayer);
						tileUrl.push(resultTileLayer);

						// Add ship Bound Url layer
						const resultBoundLayer = new TileLayer({
							source: new XYZ({
								attributions:
									'Tiles © <a href="https://earthengine.google.com/">Google Earth Engine</a>',
								url: item.boundUrl,
							}),
							title: "shipBoundLayer",
							visible: false,
						});
						mapObject.addLayer(resultBoundLayer);
						boundUrl.push(resultBoundLayer);
					});

					setResultLayer(tileUrl);
					setBoundUrl(boundUrl);

				} else {
					console.log("No s1 tiles available");
				}

				setChartData({
					labels: dateArray,
					datasets: [
						{
							label: "ship count",
							data: countArray,
							fill: false,
							borderColor: "rgba(75,192,192,1)",
							font: {
								size: 18,
								color: "red",
							},
							tension: 0.1,
						},
					],
					backgroundColor: "rgba(255, 0, 0)",
				});

				setloading(false);
				setShowVisualize(true);
				setSmallNav("Visualize");
				clearLayer();
			})
			.catch((error) => {
				setloading(false);
				console.log(error);
			});
	}

	function CallBackStartDate(startDate) {
		setStartDate(startDate);
	}
	function CallBackEndDate(endDate) {
		setEndDate(endDate);
	}

	function callBackLocation(center) {
		setSelectedLocation(center);
	}

	return (
		<div className="sidebar">
			<div className={`side_banner ${showBanner ? "" : "minimized"}`}>
				{/* Minimize button */}
				<div className="minimize-container">
					<button className="minimize-button" onClick={handleMinimize}>
						{showBanner ? (
							<i className="fa fa-window-minimize"></i>
						) : (
							<i className="fa fa-window-maximize"></i>
						)}
					</button>
				</div>
				<button className="BannerToggleButton" onClick={handleCloseBanner}>
					{showBanner ? (
						<i className="fas fa-times"></i>
					) : (
						<i className="fas fa-bars"></i>
					)}
				</button>
				<div className="content">
					<div className="BannerHeading">
						<h4>
							<center>Ship Detection</center>
						</h4>
					</div>
					<div className="BannerTabs">
						<ul>
							<li
								className="disc_tab active"
								onClick={() => handleSmallNav("Discover")}
							>
								Discover
							</li>
							<li onClick={() => handleSmallNav("Visualize")}>Visualize </li>
						</ul>
					</div>
					{smallNav === "Discover" ? (
						<div className="discover">
							<DateSelector
								handleCallBackStart={CallBackStartDate}
								startDate={startDate}
								endDate={endDate}
								handlCallBackEnd={CallBackEndDate}
							/>
							<LocationDropdown
								onLocationChange={handleLocationChange}
								onSelectedLocationChange={callBackLocation}
							/>

							<div className="lat-long-container">
								<div className="input-row">
									<label>Latitude:</label>
									<input
										type="number"
										step="0.01"
										value={latitude}
										onChange={(e) => setLatitude(e.target.value)}
									/>
								</div>
								<div className="input-row">
									<label>Longitude:</label>
									<input
										type="number"
										step="0.01"
										value={longitude}
										onChange={(e) => setLongitude(e.target.value)}
									/>
								</div>
							</div>

							

							{coordinates && (
								<div>
									<p>Latitude: {coordinates.lat.toFixed(2)}</p>
									<p>Longitude: {coordinates.lon.toFixed(2)}</p>
								</div>
							)}
							
							{loading ? (
								<div>
									<img
										className="loding"
										src="https://cdn.dribbble.com/users/121337/screenshots/1024835/loading2.gif"
										alt=""
									/>
								</div>
							) : null}

							<div>
								<button
									className="run"
									onClick={() =>
										// static_handleRun({
										handleRunCLick({
											startDate: startDate,
											endDate: endDate,
											selectedLocation: selectedLocation,
										})
									}
								>
									RUN
								</button>
							</div>
						</div>
					) : showVisualize && smallNav === "Visualize" ? (
						<Visualize
							lineChart={chartData}
							shipTileLayers={resultLayer}
							shipBoundLayers={boundUrl}
						/>
					) : null}
				</div>
			</div>
			{showConfirmation && (
				<div className="confirmation-popup">
					<p>Do you want to close the current analysis?</p>
					<button onClick={handleConfirmClose}>Yes</button>
					<button onClick={handleCancelClose}>No</button>
				</div>
			)}
			
		</div>
	);
}

export default Banner;
