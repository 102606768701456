import React from "react";
import "./oilSpillVisualize.css"

function OilSpillVisualize() {
    return(
        <div className="oilspill_legend_div">
            <span>Low</span>
            <img src=""/>
            <span>Medium</span>
        </div>
    )
}

export default OilSpillVisualize;